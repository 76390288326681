import { domStorage, globalStorage, viewStorage } from '../_globals'
import gsap from 'gsap'
import { SplitText } from 'gsap/SplitText'
gsap.registerPlugin(SplitText)
import { agenciesIntro } from './agencies-intro'

export const pageIntro = (delay) => {
  const { current } = viewStorage
  const currentDataset = current.dataset.taxiView

  const allSections = [...current.children]
  const allSectionsExceptTheFirst = allSections.slice(1)
  
  const pageHeaderTitle = current.querySelector('h1')
  // —— We need to split the title in several lines to animate them
  const titleLines = new SplitText(pageHeaderTitle, { type: 'lines' })
  // —— We stock the lines in <div class="--oh"></div> (to have the right animation)
  const titleLinesParents = new SplitText(pageHeaderTitle, { type: 'lines', linesClass: '--oh' })
  
  // Page Header
  const pageHeader = current.querySelector('.PageHeader')
  let pageHeaderSubtitle, pageHeaderVisualContainer
  if (pageHeader) {
    pageHeaderSubtitle = pageHeader.querySelector('.subtitle > span')
    pageHeaderVisualContainer = pageHeader.querySelector('.visual__container')
  }
  const scrollIndicator = current.querySelector('.ScrollIndicator')

  // Single Realisation
  const singleRealisationPageHeader = current.querySelector('.PageHeaderRealisation')
  let singleRealisationVisual, singleRealisationWrapper
  if (singleRealisationPageHeader) {
    singleRealisationVisual = singleRealisationPageHeader.querySelector('.--banner .visual')
    singleRealisationWrapper = singleRealisationPageHeader.querySelector('.wrapper')
  }

  const tl = gsap
    .timeline({
      delay: delay ? delay : 0.5,
      defaults: { ease: 'power3', duration: 1 },
      onStart: () => {
        setTimeout(() => { window.scrollTo(0, 0) }, 100)
      },
      onComplete: () => {
        gsap.set(allSectionsExceptTheFirst, { clearProps: 'all' })
        if (scrollIndicator) gsap.set(scrollIndicator, { clearProps: 'opacity' })
      }
    })
  
  // General animations
  if (pageHeaderSubtitle) tl.fromTo(pageHeaderSubtitle, { yPercent: 100 }, { yPercent: 0 }, 0)
  if (currentDataset !== 'singleRealisation' && currentDataset !== 'pageHome' && titleLines.lines.length) tl.fromTo(titleLines.lines, { yPercent: 100 }, { yPercent: 0, stagger: 0.2, ease: 'power3.out', duration: 0.7 }, 0.15)
  if (currentDataset === 'pageHome' && titleLines.lines.length) tl.fromTo(titleLines.lines, { yPercent: 100 }, { yPercent: 0, stagger: 0.2, ease: 'power3.out', duration: 1 }, 0.15)
  if (pageHeaderVisualContainer) tl.fromTo(pageHeaderVisualContainer, { clipPath: 'inset(100% 0% 0% 0%)' }, { clipPath: 'inset(0% 0% 0% 0%)', ease: 'power3.inOut' }, 0.3)

  if (scrollIndicator) tl.fromTo(scrollIndicator, { opacity: 0 }, { opacity: 1 }, 1)

  if (allSectionsExceptTheFirst.length) tl.fromTo(allSectionsExceptTheFirst, { y: 20, opacity: 0 }, { y: 0, opacity: 1 })

  // Specific Animations for Contact page
  if (currentDataset === 'pageContact') tl.add(agenciesIntro(current), 0.5)

  // Specific Animations for Single Realisation pages
  if (currentDataset === 'singleRealisation') {
    if (singleRealisationVisual) tl.fromTo(singleRealisationVisual, { opacity: 0, scale: 1.2 }, { opacity: 1, scale: 1 }, 0)
    if (singleRealisationWrapper) tl.fromTo(singleRealisationWrapper, { clipPath: 'inset(0% 0% 100% 0%)' }, { clipPath: 'inset(0% 0% 0% 0%)', duration: 1, ease: 'expo.inOut' })
  }

  return tl
}