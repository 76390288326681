import { isDEVMODE, globalStorage, domStorage, viewStorage } from '../_globals'
import gsap from 'gsap'
import { Observer } from 'gsap/Observer'
gsap.registerPlugin(Observer)
import { agenciesIntro } from '../animations/agencies-intro'

export default class Header {
	constructor() {
		this.DOM = { el: domStorage.header }
		this.DOM.brand = this.DOM.el.querySelector('.Brand')
		this.DOM.menuContainer = this.DOM.el.querySelector('.Nav__container')
		this.DOM.navLinks = this.DOM.el.querySelectorAll('.Nav__link')

		this.DOM.navBrandSvg = this.DOM.el.querySelectorAll('.Brand svg > *')

		this.DOM.navLanguages = this.DOM.el.querySelector('.Nav__languages')

		this.DOM.toggle = this.DOM.el.querySelector('.Nav__toggle')
		this.DOM.navToggleOverlay = this.DOM.toggle.querySelector('.ov')

		this.addEvents()
	}

	addEvents() {

		const { overlay, body } = domStorage
		const { el, toggle } = this.DOM

		globalStorage.openMobileMenu = () => this.open()
		globalStorage.closeMobileMenu = () => this.close()

		// Toggle
		toggle.addEventListener('click', this.toggle.bind(this))

		// Overlay
		overlay.addEventListener('click', (e) => globalStorage.menuOpen && this.close())

		// Header with Scroll events
		this.onScrollObserver = Observer.create({
			target: window,
			type: 'scroll',
			tolerance: 0,
			onUp: () => !el.classList.contains('--visible') && el.classList.add('--visible'),
			onDown: () => el.classList.contains('--visible') && el.classList.remove('--visible')
		})

		this.scrolledObserver = Observer.create({
			target: window,
			type: 'scroll',
			onChange: (self) => {
				if (window.scrollY > 50 && !body.classList.contains('--scrolled')) body.classList.add('--scrolled')
				if (window.scrollY <= 50 && body.classList.contains('--scrolled')) body.classList.remove('--scrolled')
			}
		})

		// Resize
		this.resizeTimeout = setTimeout(() => { }, 0)
		this.onResize = this.onResize.bind(this)
		this.windowWidthResize = window.innerWidth
		window.addEventListener('resize', this.onResize)
	}

	toggle(e) {
		e.preventDefault()

		globalStorage.menuOpen ? this.close() : this.open()
	}

	open() {
		const { menuContainer, toggle, navToggleOverlay, navLinks, navLanguages, navBrandSvg } = this.DOM

		this.tlOpen = gsap
			.timeline({
				defaults: {},
				onStart: () => {
					// console.log('open')

					// viewScroll.scroll.stop()
					globalStorage.menuOpen = true

					toggle.classList.add('--disable')
				},
				onComplete: () => {
					toggle.classList.remove('--disable')

					// Cleaning styles
					gsap.set(navLinks, { clearProps: 'all' })
				}
			})
			.add(() => domStorage.body.classList.add('showMenu'), 0.2)
			.fromTo(menuContainer, { clipPath: 'inset(0% 0% 100% 0%)' }, { clipPath: 'inset(0% 0% 0% 0%)', ease: 'expo.inOut', duration: 1, overwrite: true }, 0)
			.fromTo(navToggleOverlay, { clipPath: 'inset(0% 0% 100% 0%)' }, { clipPath: 'inset(0% 0% 0% 0%)', ease: 'expo.inOut', duration: 1, overwrite: true }, 0)
			.to(navLanguages, { color: '#ffffff', duration: 1, ease: 'expo.inOut', overwrite: true }, 0)
			.to(navBrandSvg, { fill: '#ffffff', duration: 1, ease: 'expo.inOut', overwrite: true }, 0)
			.fromTo(navLinks, { yPercent: 100 }, { yPercent: 0, ease: 'power3.out', stagger: 0.065, duration: 0.8, overwrite: true }, 0.5)
			.add(agenciesIntro(this.DOM.el), 0.5)
	}

	close() {
		const { viewScroll } = viewStorage
		const { menuContainer, toggle, navToggleOverlay, navLanguages, navBrandSvg } = this.DOM


		this.tlClose = gsap
			.timeline({
				defaults: {},
				onStart: () => {
					toggle.classList.add('--disable')
				},
				onComplete: () => {
					globalStorage.menuOpen = false
					toggle.classList.remove('--disable')
				}
			})
			.to(menuContainer, { clipPath: 'inset(100% 0% 0% 0%)', ease: 'expo.inOut', duration: 1 }, 0.1)
			.to(navToggleOverlay, { clipPath: 'inset(100% 0% 0% 0%)', ease: 'expo.inOut', duration: 0.95 }, 0)
			.to(navLanguages, { color: '#0C0C0C', ease: 'expo.inOut', duration: 1 }, 0)
			.to(navBrandSvg, { fill: '#0C0C0C', ease: 'expo.inOut', duration: 1 }, 0)
			.add(() => domStorage.body.classList.remove('showMenu'), 0.3)
	}

	onResize() {

		clearTimeout(this.resizeTimeout)
		this.resizeTimeout = setTimeout(() => {

			if (this.windowWidthResize !== window.innerWidth) {
				// if (isDEVMODE) console.log('updateHeaderResize')
				this.windowWidthResize = window.innerWidth
				if (globalStorage.menuOpen === true) this.close()
			}

		}, 250)
	}
}
