import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import LoadMore from '../modules/load-more'

export default class ArchiveRealisations extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      cards: viewStorage.current.querySelectorAll('.RealisationCard'),
      filters: viewStorage.current.querySelectorAll('.filters ._categories li')
    }

    this.init()

  }

  init() {
    if (isDEVMODE) console.log(`Init Page : ${this.content.dataset.taxiView}`)
    if (isDEVMODE) console.log(viewStorage.current)

    const { cards, filters } = this.DOM

    // 1. Container, 2. Cards, 3. Limit
    if (cards.length) this.loadMore = new LoadMore(viewStorage.current, cards, 6)

    // filter by category
    if (filters.length)
      filters.forEach(filter => {
        filter.addEventListener('click', (e) => this.filterByCategory(e));
      })

  }

  onLeaveCompleted() {
    const { loadMore, filters } = this

    if (loadMore) loadMore.destroy()
  }

  filterByCategory(e, id) {
    e.preventDefault();
    const { cards, filters } = this.DOM

    if (filters.length) {
      filters.forEach(filter => {
        if (filter.classList.contains('active') && e.currentTarget !== filter) {
          filter.classList.remove('active');
        }
      })
    }

    e.currentTarget.classList.toggle('active')

    let ids = [];
    if (filters.length) {
      filters.forEach(filter => {
        if (filter.classList.contains('active')) {
          ids.push(filter.dataset.id);
        }
      })
    }

    if (isDEVMODE) console.log('ids : ', ids)

    // Loop through each div
    cards.forEach(card => {
      const categories = card.getAttribute('data-category').split(',');
      const hasCommonElement = categories.some(item => ids.includes(item));

      if (isDEVMODE) console.log('categories : ', categories)
      if (isDEVMODE) console.log('hasCommonElement : ', hasCommonElement)

      if (hasCommonElement || ids.length == 0) {
        card.style.display = 'block';
      } else {
        card.style.display = 'none';
      }
    });
  }
}
