import { isDEVMODE, globalStorage, domStorage, viewStorage } from '../_globals'
import gsap from 'gsap'
import { lerp, getMousePos } from '../utils'

export default class WknCursor {

  constructor(cursor = document.getElementById('Cursor')) {

    if (globalStorage.isTouch) {
      domStorage.body.removeChild(cursor)
      return
    }

    this.DOM = { cursor }
    
    this.DOM.cursorSVG = this.DOM.cursor.querySelector('svg')

    this.state = {
      pos: { x: window.innerWidth / 2, y: window.innerHeight / 2 },
      speed: 0.1
    }

    this.mainColor = '#FF6B80'

    this.mouse = { x: window.innerWidth / 2, y: window.innerHeight / 2 }

    this.init()
    this.addEvents()

  }

  init() {
    if (!this.DOM.cursor) return
    
    gsap.set(this.DOM.cursor, {xPercent: -50, yPercent: -50})

    this.cursorXSetter = gsap.quickSetter(this.DOM.cursor, 'x', 'px')
    this.cursorYSetter = gsap.quickSetter(this.DOM.cursor, 'y', 'px')

  }

  addEvents() {
    const { cursor, cursorSVG, invertSections } = this.DOM

    this.onMouseMove = this.onMouseMove.bind(this)
    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
    window.addEventListener('mousemove', this.onMouseMove)

    this.onRender = this.onRender.bind(this)
    gsap.ticker.add(this.onRender)

    this.addHovers(domStorage.body)

  }

  onMouseMove(e) {
    this.mouse = getMousePos(e)
  }

  onRender() {

    const { pos, speed } = this.state
    const { mouse } = this

    this.state.pos.x = lerp(pos.x, mouse.x, speed)
    this.state.pos.y = lerp(pos.y, mouse.y, speed)

    this.cursorXSetter(this.state.pos.x)
    this.cursorYSetter(this.state.pos.y)
  }

  onMouseEnter() {
    if(this.DOM.cursor) this.DOM.cursor.classList.add('--hovering')
  }

  onMouseLeave() {    
    if (this.DOM.cursor) this.DOM.cursor.classList.remove('--hovering')
  }

  addInvert() {
    if (!this.DOM.cursor) return
    
    const invertSections = document.querySelectorAll('.--cursor-invert')
    const { cursorSVG } = this.DOM

    if (invertSections.length) invertSections.forEach(section => {
      section.addEventListener('mouseenter', () => { gsap.to(cursorSVG, { fill: '#0C0C0C', ease: 'circ.out', overwrite: true }) })
      section.addEventListener('mouseleave', () => { gsap.to(cursorSVG, { fill: this.mainColor, ease: 'circ.out', overwrite: true }) })
    })
  }

  removeInvert() {
    if (!this.DOM.cursor) return
    
    const invertSections = document.querySelectorAll('.--cursor-invert')
    const { cursorSVG } = this.DOM

    if (invertSections.length) invertSections.forEach(section => {
      section.removeEventListener('mouseenter', () => { gsap.to(cursorSVG, { fill: '#0C0C0C', ease: 'circ.out', overwrite: true }) })
      section.removeEventListener('mouseleave', () => { gsap.to(cursorSVG, { fill: this.mainColor, ease: 'circ.out', overwrite: true }) })
    })
  }

  addHovers() {
    if (!this.DOM.cursor) return

    this.hoveredItems = []
    const links = gsap.utils.toArray(document.querySelectorAll('a'))
    const buttons = gsap.utils.toArray(document.querySelectorAll('button'))

    links.forEach(item => {
      item.addEventListener('mouseenter', this.onMouseEnter)
      item.addEventListener('mouseleave', this.onMouseLeave)
      this.hoveredItems.push(item)
    })

    buttons.forEach(item => {
      item.addEventListener('mouseenter', this.onMouseEnter)
      item.addEventListener('mouseleave', this.onMouseLeave)
      this.hoveredItems.push(item)
    })

  }

  removeHovers() {
    if (!this.DOM.cursor) return

    this.DOM.cursor.classList.remove('--hovering')

    this.hoveredItems.forEach(item => {
      item.removeEventListener('mouseenter', this.onMouseEnter)
      item.removeEventListener('mouseleave', this.onMouseLeave)
    });
  }

  destroy() {

    if (isDEVMODE) console.log('Destroy WknCursor')
    window.removeEventListener('mousemove', this.onMouseMove)
    gsap.ticker.remove(this.onRender)

    if (this.DOM.cursor) this.DOM.cursor.parentNode.removeChild(cursor)

  }
}
